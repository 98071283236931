import { useIntl } from "react-intl";
import { EnableSidebar, PageTitle } from "../../../../_metronic/layout/core";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

const AddUser = () => {
  const intl = useIntl();
  const [user, setUser] = useState<any>({
    name: "",
    email: "",
    roles: [],
  });
  const [roles, setRoles] = useState<any[]>([]);
  const navigate = useNavigate();

  const notify = () =>
    toast.success(intl.formatMessage({ id: "success_action" }));

  async function updateUser() {
    await axios
      .post(process.env.REACT_APP_API_URL + "/user", user, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
        },
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 422) {
          const errors = error?.response?.data?.errors;
          for (const key in errors) {
            if (Object.prototype.hasOwnProperty.call(errors, key)) {
              const element = errors[key];
              toast.error(element[0], {
                duration: 5000,
              });
            }
          }
        }
      })
      .then((response) => {
        // redirect back to users page
        if (response?.status === 200) {
          notify();
        }
        // navigate("/users", { replace: true });
      });
  }

  async function getRoles() {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/settings/roles",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
        },
      }
    );
    setRoles(response.data.data);
  }

  useEffect(() => {
    getRoles();
  }, []);

  if (!user) {
    return (
      <PageTitle breadcrumbs={[]}>
        <h1>Loading...</h1>
      </PageTitle>
    );
  }

  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "users" })}
      </PageTitle>
      <div className="card text-left">
        <div className="card-body">
          <div className="row mt-3">
            <div className="col-lg-6">
              <label className="form-label">
                {intl.formatMessage({ id: "name" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={user.name}
                onChange={(e) => {
                  setUser({ ...user, name: e.currentTarget.value });
                }}
              />
            </div>
            <div className="col-lg-6">
              <label className="form-label">E-mail</label>
              <input
                type="text"
                className="form-control"
                defaultValue={user.email}
                onChange={(e) => {
                  setUser({ ...user, email: e.currentTarget.value });
                }}
              />
            </div>
          </div>
          <div className="row mt-10">
            <div className="col-lg-6">
              <h3>{intl.formatMessage({ id: "role" })}</h3>

              {roles.map((role) => (
                <div className="mb-2" key={role.id}>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={role.id}
                      id="flexSwitchDefault"
                      checked={user.roles.includes(role.id)}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setUser({
                            ...user,
                            roles: [...user.roles, role.id],
                          });
                        } else {
                          setUser({
                            ...user,
                            roles: user.roles.filter((r) => r !== role.id),
                          });
                        }
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchDefault"
                    >
                      {intl.formatMessage({ id: role.name })}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              updateUser();
            }}
          >
            {intl.formatMessage({ id: "save" })}
          </button>
        </div>
      </div>
    </EnableSidebar>
  );
};

export default AddUser;
