import { FC } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useIntl } from "react-intl";
import Lottie from "lottie-react";
import RestrictedAnimation from "../../../../lottie/RestrictedAnimation.json";

const Error403: FC = () => {
  const intl = useIntl();
  return (
    <>
      {/* begin::Title */}
      <h1 className="fw-bolder fs-2hx text-gray-900 mb-4 text-center">Oops!</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className="fw-semibold fs-6 text-gray-500 mb-7 text-center">
        {intl.formatMessage({ id: "errors.403" })}
      </div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className="mb-3 mw-500px text-center mx-auto">
        <Lottie animationData={RestrictedAnimation} loop={true} />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className="mb-0 text-center">
        <Link to="/dashboard" className="btn btn-sm btn-primary">
          {intl.formatMessage({ id: "to_dashboard" })}
        </Link>
      </div>
      {/* end::Link */}
    </>
  );
};

export { Error403 };
