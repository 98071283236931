/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { KTIcon } from "../../../helpers";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { faUser } from "@fortawesome/pro-solid-svg-icons";

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="fa-house-building"
      />
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({ id: "database" })}
          </span>
        </div>
      </div>
      <AsideMenuItem
        to="/customers"
        title={intl.formatMessage({ id: "customers" })}
        hasBullet={false}
        fontIcon="fa-people-group"
      />
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({ id: "contact" })}
          </span>
        </div>
      </div>

      {/* <AsideMenuItemWithSub
        to="/crafted/accounts"
        title="Accounts"
        fontIcon="fa-users"
      >
        <AsideMenuItem
          to="/crafted/account/overview"
          title="Overview"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/account/settings"
          title="Settings"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to="/error" title="Errors" fontIcon="bi-sticky">
        <AsideMenuItem to="/error/404" title="Error 404" hasBullet={true} />
        <AsideMenuItem to="/error/500" title="Error 500" hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/crafted/widgets"
        title="Widgets"
        // fontIcon="bi-layers"
      >
        <AsideMenuItem
          to="/crafted/widgets/lists"
          title="Lists"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/widgets/statistics"
          title="Statistics"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/widgets/charts"
          title="Charts"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/widgets/mixed"
          title="Mixed"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/widgets/tables"
          title="Tables"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/widgets/feeds"
          title="Feeds"
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Apps
          </span>
        </div>
      </div> */}
      <AsideMenuItem
        to="/emails"
        title={intl.formatMessage({ id: "email_center" })}
        fontIcon="fa-envelopes-bulk"
        hasBullet={false}
      />
      <AsideMenuItemWithSub
        to="/apps/chat"
        title="Chat"
        // fontIcon="black-right"
      >
        <AsideMenuItem
          to="/apps/chat/private-chat"
          title="Private Chat"
          // fontIcon="bi-chat-left-text"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/apps/chat/group-chat"
          title="Group Chart"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/apps/chat/drawer-chat"
          title="Drawer Chart"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="#"
        fontIcon="fa-cog"
        title={intl.formatMessage({ id: "settings" })}
        // fontIcon="black-right"
      >
        <AsideMenuItem
          to="/users"
          fontIcon="fa-users"
          title={intl.formatMessage({ id: "MENU.USER_MANAGEMENT" })}
          // fontIcon="bi-layers"
        />
      </AsideMenuItemWithSub>
    </>
  );
}
