import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";

import { useIntl } from "react-intl";
import axios from "axios";
import { useThemeMode } from "../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";

const initialUser = {
  name: "",
  language: undefined as string | undefined,
  email: "",
  avatar: "/media/avatars/blank.png",
};
type User = typeof initialUser;

const ProfileDetails: React.FC = () => {
  const [user, setUser] = useState<User>(initialUser);
  const intl = useIntl();
  const [avataImage, setAvataImage] = useState<string | null>();
  const { mode } = useThemeMode();

  const [loading, setLoading] = useState(false);
  const getUser = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/user/profile",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
          },
        }
      );
      setUser(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    await updateUser();
  }

  async function updateUser() {
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/user/profile",
        {
          name: user.name,
          language: user.language,
          email: user.email,
          avatar: avataImage,
          theme: mode,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">
            {intl.formatMessage({ id: "my_profile" })}
          </h3>
        </div>
      </div>

      <div id="kt_account_profile_details" className="collapse show">
        <form
          onSubmit={handleFormSubmit}
          className="form"
          encType="multipart/form-data"
        >
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <div className="col-lg-6">
                <label className="form-label">Avatar</label>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  translate="yes"
                  onChange={(e) => {
                    const reader = new FileReader();
                    const img = e.target.files?.[0];
                    reader.readAsDataURL(img as Blob);
                    reader.onloadend = () => {
                      console.log(reader.result);
                      setAvataImage(reader.result as string);
                    };
                  }}
                  className="form-control form-control-lg form-control-solid"
                  placeholder={intl.formatMessage({ id: "name" })}
                />
              </div>
              {/* <div className="col-lg-6">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      "/media/avatars/blank.png"
                    )})`,
                  }}
                >
                  <div
                    className="image-input-wrapper w-125px h-125px"
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(user?.avatar)})`,
                    }}
                  ></div>
                </div>
              </div> */}
            </div>

            <div className="row mb-6">
              <div className="col-lg-6">
                <label className="form-label">
                  {intl.formatMessage({ id: "name" })}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder={intl.formatMessage({ id: "name" })}
                  defaultValue={user?.name}
                />
              </div>
              <div className="col-lg-6">
                <label className="form-label">E-mail</label>
                <input
                  type="email"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="E-mail"
                  defaultValue={user?.email}
                />
              </div>
            </div>
            <div className="row mb-6">
              <div className="col-lg-6">
                <label className="form-label">
                  {intl.formatMessage({ id: "password" })}
                </label>
                <input
                  type="password"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={intl.formatMessage({ id: "password" })}
                />
              </div>
              <div className="col-lg-6">
                <label className="form-label">
                  {intl.formatMessage({ id: "confirm_password" })}
                </label>
                <input
                  type="password"
                  autoComplete="new-password"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={intl.formatMessage({ id: "confirm_password" })}
                />
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {intl.formatMessage({ id: "language" })}
              </label>
              <div className="col-lg-8 fv-row">
                <select
                  className="form-select form-select-solid form-select-lg"
                  defaultValue={user?.language}
                  onChange={(e) => {
                    setUser({ ...user, language: e.currentTarget.value });
                  }}
                >
                  <option value="en">English</option>
                  <option value="hu">Magyar</option>
                </select>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="submit"
              className="btn btn-outline btn-outline-primary"
              disabled={loading}
            >
              {!loading && intl.formatMessage({ id: "save" })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({ id: "loading" }) + " ..."}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { ProfileDetails };
