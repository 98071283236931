import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { PageTitle } from "../../../../_metronic/layout/core";
import GenFontAwe from "../../../../components/GenFontAwe";

import { Editor } from "@tinymce/tinymce-react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

type mailId = string;

export function ViewMail({ id: undefined }) {
  // get mail id from route
  const params = useParams();

  const [subject, setSubject] = useState("");
  const [sender, setSender] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [content, setContent] = useState<any>({ __html: "" });
  const [textContent, setTextContent] = useState("");

  const [reply, setReply] = useState<any>({ __html: "" });
  const intl = useIntl();

  const [contentType, setContentType] = useState("html");

  const mailEditor = useRef<any>(null);

  async function replyMail() {
    const url = process.env.REACT_APP_API_URL + `/mail/${params.id}/reply`;
    await axios
      .post(
        url,
        {
          content: {
            html_content: reply,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
            Accept: "application/json",
          },
        }
      )
      .catch((error) => {
        console.log(error);
      })
      .then((response) => {
        toast.success(intl.formatMessage({ id: "mail_sent" }), {
          theme: "colored",
          autoClose: 1500,
          hideProgressBar: true,
        });
        if (mailEditor) {
          console.log(mailEditor.current);
          mailEditor.current.setContent("");
          setReply("");
        }
      });
  }

  useEffect(() => {
    getMail(params.id);
  }, []);

  async function getMail(id) {
    const url = process.env.REACT_APP_API_URL + "/mail/" + id;
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
          Accept: "application/json",
        },
      })
      .catch((error) => {
        console.log(error);
      })
      .then((response) => {
        console.log(response?.data);
        setSubject(response?.data.subject);
        setSender(response?.data.from_name);
        setSenderEmail(response?.data.from);
        setContent({ __html: response?.data.content.html_content });
        setTextContent(response?.data.content.text_content);
      });
  }
  return (
    <div>
      <PageTitle breadcrumbs={[]} description={sender + " - " + senderEmail}>
        {subject}
      </PageTitle>
      <div className="card">
        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-sm btn-secondary">
              <GenFontAwe nameIco="fa-sync" customClass="me-2" />
              Feladattá alakítás
            </button>
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => {
                setContentType(contentType === "html" ? "text" : "html");
              }}
            >
              {contentType === "html" ? (
                <GenFontAwe nameIco="fa-mountain-sun" />
              ) : (
                <GenFontAwe nameIco="fa-align-left" />
              )}
            </button>
          </div>
        </div>
        <div className="card-body">
          <div
            className="mh-400px overflow-auto bg-light p-5"
            dangerouslySetInnerHTML={contentType === "html" ? content : null}
          >
            {contentType === "text" ? textContent : null}
          </div>
          <div className="separator separator-dashed my-5"></div>

          <Editor
            init={{
              menubar: false,
              statusbar: false,
              language: "hu_HU",
              skin: "oxide",
            }}
            onInit={(evt, editor) => (mailEditor.current = editor)}
            onChange={(e) => {
              setReply(e.target.getContent());
            }}
            apiKey="ldiyj7nwyvrmkfjhgibjyneuvgb5vmld1grphqetzg2173lu"
          ></Editor>
        </div>
        <div className="card-footer text-end">
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              replyMail();
            }}
          >
            <GenFontAwe nameIco="fa-reply" customClass="me-2" />
            {intl.formatMessage({ id: "reply" })}
          </button>
        </div>
      </div>
    </div>
  );
}
