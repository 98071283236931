import { useIntl } from "react-intl";
import { EnableSidebar, PageTitle } from "../../../../_metronic/layout/core";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

const AddCustomer = () => {
  const intl = useIntl();
  const [customer, setCustomer] = useState<any>({
    name: "",
    email: "",
    company_name: "",
    tax_number: "",
    tax_number_eu: "",
    country: "",
    city: "",
    zip: "",
    address: "",
    address2: "",
    phone: "",
    bank_account: "",
    bank_name: "",
    bank_swift: "",
    bank_iban: "",
  });
  const navigate = useNavigate();

  const notify = () =>
    toast.success(intl.formatMessage({ id: "success_action" }));

  async function updateCustomer() {
    await axios
      .post(process.env.REACT_APP_API_URL + "/customer", customer, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
        },
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 422) {
          const errors = error?.response?.data?.errors;
          for (const key in errors) {
            if (Object.prototype.hasOwnProperty.call(errors, key)) {
              const element = errors[key];
              toast.error(element[0], {
                duration: 5000,
              });
            }
          }
        }
      })
      .then((response) => {
        if (response?.status === 201) {
          notify();
          navigate("/customers", { replace: true });
        }
      });
  }

  useEffect(() => {}, []);

  if (!customer) {
    return (
      <PageTitle breadcrumbs={[]}>
        <h1>{intl.formatMessage({ id: "please_wait" })}...</h1>
      </PageTitle>
    );
  }

  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "customers" })}
      </PageTitle>
      <div className="card text-left">
        <div className="card-header">
          <div className="card-title">
            {intl.formatMessage({ id: "main_data" })}
          </div>
        </div>
        <div className="card-body">
          <div className="row mt-3">
            <div className="col-lg-6">
              <label className="form-label">
                {intl.formatMessage({ id: "contact_or_name" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.name}
                onChange={(e) => {
                  setCustomer({ ...customer, name: e.currentTarget.value });
                }}
              />
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                {intl.formatMessage({ id: "company_name" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.company_name}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    company_name: e.currentTarget.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <label className="form-label">E-mail</label>
              <input
                type="email"
                className="form-control"
                defaultValue={customer.email}
                onChange={(e) => {
                  setCustomer({ ...customer, email: e.currentTarget.value });
                }}
              />
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                {intl.formatMessage({ id: "phone" })}
              </label>
              <input
                type="phone"
                className="form-control"
                defaultValue={customer.phone}
                onChange={(e) => {
                  setCustomer({ ...customer, phone: e.currentTarget.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="card-header">
          <div className="card-title">
            {intl.formatMessage({ id: "address_data" })}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3">
              <label className="form-label">
                {intl.formatMessage({ id: "country_code" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.country}
                maxLength={2}
                onChange={(e) => {
                  setCustomer({ ...customer, country: e.currentTarget.value });
                }}
              />
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                {intl.formatMessage({ id: "zip" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.zip}
                maxLength={6}
                onChange={(e) => {
                  setCustomer({ ...customer, zip: e.currentTarget.value });
                }}
              />
            </div>
            <div className="col-lg-5">
              <label className="form-label">
                {intl.formatMessage({ id: "city" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.city}
                onChange={(e) => {
                  setCustomer({ ...customer, city: e.currentTarget.value });
                }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-7">
              <label className="form-label">
                {intl.formatMessage({ id: "address" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.address}
                onChange={(e) => {
                  setCustomer({ ...customer, address: e.currentTarget.value });
                }}
              />
            </div>
            <div className="col-lg-5">
              <label className="form-label">
                {intl.formatMessage({ id: "address2" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.address2}
                onChange={(e) => {
                  setCustomer({ ...customer, address2: e.currentTarget.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="card-header">
          <div className="card-title">
            {intl.formatMessage({ id: "bank_data" })}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6">
              <label className="form-label">
                {intl.formatMessage({ id: "bank_name" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.bank_name}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    bank_name: e.currentTarget.value,
                  });
                }}
              />
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                {intl.formatMessage({ id: "iban" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.bank_iban}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    bank_iban: e.currentTarget.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              updateCustomer();
            }}
          >
            {intl.formatMessage({ id: "save" })}
          </button>
        </div>
      </div>
    </EnableSidebar>
  );
};

export default AddCustomer;
