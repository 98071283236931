import { useIntl } from "react-intl";
import { EnableSidebar, PageTitle } from "../../../../_metronic/layout/core";
import { EmailSidebar } from "../components/EmailSidebar";
import { EmailList } from "../components/EmailList";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { get } from "http";
import { ViewMail } from "../components/ViewMail";

type action = "view" | "add" | "edit";
type id = string;
const EmailsPage = ({ action = "list", id = undefined }) => {
  const intl = useIntl();

  const [currentList, setCurrentList] = useState("inbox");

  const [mailsChecked, setMailsChecked] = useState(false);

  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const [labels, setLabels] = useState([]);
  const [totalUnreadInboxCount, setTotalUnreadInboxCount] = useState(0);

  async function getMailCounts() {
    const url = process.env.REACT_APP_API_URL + "/mail/getMailCount";
    setLoading(true);
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
          Accept: "application/json",
        },
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      })
      .then((response) => {
        console.log(response?.data);
        setLoading(false);
        setLabels(response?.data.labels);
        setTotalUnreadInboxCount(response?.data.totalUnreadInboxCount);
        setMailsChecked(true);
      });
  }

  React.useEffect(() => {
    getMailCounts();
  }, []);

  if (!mailsChecked) {
    return <div>Loading...</div>;
  }
  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "email_center" })}
      </PageTitle>
      <div className="d-flex flex-column flex-lg-row">
        <div className="d-none d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-275px">
          <EmailSidebar
            initLabels={labels}
            unreadCount={totalUnreadInboxCount}
          />
        </div>
        <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
          {action === "list" && <EmailList folder={currentList} />}
          {action === "view" && <ViewMail id={id} />}
        </div>
      </div>
    </EnableSidebar>
  );
};

export default EmailsPage;
