import { useIntl } from "react-intl";
import { EnableSidebar, PageTitle } from "../../../../_metronic/layout/core";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import Lottie from "lottie-react";
import loadingAnimation from "../../../../lottie/loadingAnimation.json";
import { KTCardBody } from "../../../../_metronic/helpers";

const ViewCustomer = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [customer, setCustomer] = useState<any>();
  const navigate = useNavigate();

  const confirm = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: intl.formatMessage({ id: "delete_confirm" }),
      icon: "fa-solid fa-exclamation-triangle text-danger",
      rejectLabel: intl.formatMessage({ id: "no" }),
      acceptIcon: "fa-solid fa-check",
      acceptLabel: intl.formatMessage({ id: "yes" }),
      accept: async () => await deleteCustomer(),
    });
  };

  async function deleteCustomer() {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/customer/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
        },
      })
      .catch((error) => {
        console.warn(error);
      })
      .then((response) => {
        console.warn(response?.status);
        if (response?.status !== 204) {
          toast.error(intl.formatMessage({ id: "no_permission" }));
        } else {
          notify();
          navigate("/customers", { replace: true });
        }
      });
  }

  async function getCustomer() {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/customer/" + id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
        },
      }
    );
    setCustomer(response.data.data);
  }
  const notify = () =>
    toast.success(intl.formatMessage({ id: "success_action" }));

  async function updateCustomer() {
    await axios
      .put(process.env.REACT_APP_API_URL + "/customer/" + id, customer, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
        },
      })
      .catch((error) => {
        console.log(error);
      })
      .then((response) => {
        console.log(response);
        // redirect back to customers page
        notify();
        navigate("/customers", { replace: true });
      });
  }

  useEffect(() => {
    getCustomer();
  }, []);

  if (!customer) {
    return (
      <div className="mt-25 position-absolute top-25 w-100">
        <Lottie
          animationData={loadingAnimation}
          loop={true}
          className="w-75 h-100px "
        />
      </div>
    );
  }

  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "customers" })}
      </PageTitle>
      <div className="card text-left">
        <div className="card-body">
          <div className="row mt-3">
            <div className="col-lg-6">
              <label className="form-label">
                {intl.formatMessage({ id: "contact_or_name" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.name}
                onChange={(e) => {
                  setCustomer({ ...customer, name: e.currentTarget.value });
                }}
              />
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                {intl.formatMessage({ id: "company_name" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.company_name}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    company_name: e.currentTarget.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <label className="form-label">E-mail</label>
              <input
                type="email"
                className="form-control"
                defaultValue={customer.email}
                onChange={(e) => {
                  setCustomer({ ...customer, email: e.currentTarget.value });
                }}
              />
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                {intl.formatMessage({ id: "phone" })}
              </label>
              <input
                type="phone"
                className="form-control"
                defaultValue={customer.phone}
                onChange={(e) => {
                  setCustomer({ ...customer, phone: e.currentTarget.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="card-header">
          <div className="card-title">
            {intl.formatMessage({ id: "address_data" })}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3">
              <label className="form-label">
                {intl.formatMessage({ id: "country_code" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.country}
                maxLength={2}
                onChange={(e) => {
                  setCustomer({ ...customer, country: e.currentTarget.value });
                }}
              />
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                {intl.formatMessage({ id: "zip" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.zip}
                maxLength={6}
                onChange={(e) => {
                  setCustomer({ ...customer, zip: e.currentTarget.value });
                }}
              />
            </div>
            <div className="col-lg-5">
              <label className="form-label">
                {intl.formatMessage({ id: "city" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.city}
                onChange={(e) => {
                  setCustomer({ ...customer, city: e.currentTarget.value });
                }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-7">
              <label className="form-label">
                {intl.formatMessage({ id: "address" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.address}
                onChange={(e) => {
                  setCustomer({ ...customer, address: e.currentTarget.value });
                }}
              />
            </div>
            <div className="col-lg-5">
              <label className="form-label">
                {intl.formatMessage({ id: "address2" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.address2}
                onChange={(e) => {
                  setCustomer({ ...customer, address2: e.currentTarget.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="card-header">
          <div className="card-title">
            {intl.formatMessage({ id: "bank_data" })}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6">
              <label className="form-label">
                {intl.formatMessage({ id: "bank_name" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.bank_name}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    bank_name: e.currentTarget.value,
                  });
                }}
              />
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                {intl.formatMessage({ id: "iban" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer.bank_iban}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    bank_iban: e.currentTarget.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col text-start">
              <button
                type="button"
                className="btn btn-danger"
                onClick={confirm}
              >
                {intl.formatMessage({ id: "delete" })}
              </button>
              <ConfirmPopup />
            </div>
            <div className="col text-end">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  updateCustomer();
                }}
              >
                {intl.formatMessage({ id: "save" })}
              </button>
            </div>
          </div>
        </div>
      </div>
    </EnableSidebar>
  );
};

export default ViewCustomer;
