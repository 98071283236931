import React, { createContext, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import { useAuth } from "./Auth";
import axios from "axios";
import { toast } from "react-toastify";

interface SocketContextValue {
  //users array
  socket: Socket | null;
}
declare global {
  interface Window {
    Socket: any;
  }
}

export const SocketContext = createContext<SocketContextValue>({
  socket: null,
});

export function SocketProvider({ children }) {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      const currentSocket = io(
        "https://radiant-ravine-22451-c75fae5f53b3.herokuapp.com:443",
        // "localhost:3000",
        {
          transports: ["websocket"],
          reconnection: true,
          autoConnect: true,
        }
      );
      currentSocket.on("connect", () => {});

      setSocket(currentSocket);

      currentSocket?.on("new-message", (e) => {
        toast.success(e, {
          onClick: () => {
            console.log("close");
          },
          closeOnClick: true,
          pauseOnHover: true,
          closeButton: false,
        });
      });

      return () => {
        currentSocket.close();
      };
    }
  }, [currentUser]);

  return (
    <SocketContext.Provider
      value={{
        socket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}
