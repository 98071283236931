import axios from "axios";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../../_metronic/layout/core";
import GenFontAwe from "../../../../components/GenFontAwe";
import Input from "../../../CoreComponents/Input";
import Lottie from "lottie-react";
import loadingAnimation from "../../../../lottie/loadingAnimation.json";
import { toast } from "react-toastify";
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import { Link } from "react-router-dom";

export function EmailList({ folder = "inbox", mails = [] }) {
  const intl = useIntl();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [endpoint, setEndpoint] = useState<any>(null);
  const [emails, setEmails] = useState<any>(mails);
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const { mode } = useThemeMode();

  async function handleDelete() {
    if (selectedItems.length == 0) {
      toast.warning(intl.formatMessage({ id: "no_selected_items" }), {
        theme: mode === "light" ? "light" : "dark",
        autoClose: 1500,
        hideProgressBar: true,
      });
    }
  }
  async function deleteSelectedMails() {}

  useEffect(() => {
    return () => {
      console.log("unmounting...");
    };
  }, []);

  useEffect(() => {
    console.log(folder);
    switch (folder) {
      case "inbox":
        setEndpoint(process.env.REACT_APP_API_URL + "/mail/inbox");
        break;
      case "sent":
        setEndpoint(process.env.REACT_APP_API_URL + "/mail/sent");
        break;

      default:
        break;
    }
  }, []);

  async function getMails(endpoint) {
    setLoading(true);
    await axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
          Accept: "application/json",
        },
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      })
      .then((response) => {
        setLoading(false);
        console.log(response?.data.data);
        setEmails(response?.data.data);
      });
  }

  useEffect(() => {
    if (endpoint) {
      getMails(endpoint);
    }
  }, [endpoint]);

  return (
    <div className="card">
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: folder })}
      </PageTitle>
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="d-flex flex-wrap gap-2">
          <div className="form-check form-check-sm form-check-custom form-check-solid me-4 me-lg-7">
            <input
              className="form-check-input"
              type="checkbox"
              data-kt-check="true"
              data-kt-check-target="#kt_inbox_listing .form-check-input"
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  emails.map((email: any) => {
                    return setSelectedItems((selectedItems) => [
                      ...selectedItems,
                      email.id,
                    ]);
                  });
                } else {
                  return setSelectedItems([]);
                }
              }}
            ></input>
          </div>
          <button className="btn btn-sm btn-icon btn-light btn-active-light-primary">
            <GenFontAwe nameIco="fa-sync" />
          </button>
          <button
            className="btn btn-sm btn-icon btn-light btn-active-light-primary"
            onClick={handleDelete}
          >
            <GenFontAwe nameIco="fa-trash" />
          </button>
          {/* button group dropdown with selected items */}
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-sm btn-icon btn-light btn-active-light-primary"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <GenFontAwe nameIco="fa-ellipsis-v" />
            </button>
            <div className="dropdown-menu dropdown-menu-end pt-0 shadow">
              <ul className="nav ">
                <li className="nav-header fs-6 px-3 py-1 w-100">
                  <span className="fw-bolder">
                    {intl.formatMessage({ id: "MENU.ACTIONS" })}
                  </span>
                </li>
                <li className="nav-item">
                  <button className="nav-link">
                    <span className="nav-icon me-2">
                      <GenFontAwe nameIco="fa-inbox" />
                    </span>
                    <span className="nav-text">
                      {intl.formatMessage({ id: "inbox" })}
                    </span>
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link">
                    <span className="nav-icon me-2">
                      <GenFontAwe nameIco="fa-share-square" />
                    </span>
                    <span className="nav-text">
                      {intl.formatMessage({ id: "sent" })}
                    </span>
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link">
                    <span className="nav-icon me-2">
                      <GenFontAwe nameIco="fa-trash" />
                    </span>
                    <span className="nav-text">
                      {intl.formatMessage({ id: "trash" })}
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center flex-wrap gap-2">
          <Input
            type="search"
            className="form-control form-control-sm w-200px"
            placeholder={intl.formatMessage({ id: "search_in_mails" })}
          />
          <button
            className="btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none"
            data-bs-toggle="tooltip"
            data-bs-dismiss="click"
            data-bs-placement="top"
            id="kt_inbox_aside_toggle"
            aria-label="Toggle inbox menu"
            data-bs-original-title="Toggle inbox menu"
            data-kt-initialized="1"
          >
            <i className="ki-duotone ki-burger-menu-2 fs-3 m-0">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
              <span className="path5"></span>
              <span className="path6"></span>
              <span className="path7"></span>
              <span className="path8"></span>
              <span className="path9"></span>
              <span className="path10"></span>
            </i>{" "}
          </button>
        </div>
      </div>
      <div className="card-body p-0">
        {loading && (
          <div className="w-400px mx-auto">
            <Lottie animationData={loadingAnimation} loop={true} />
          </div>
        )}
        <table
          id="kt_inbox_listing"
          className="table table-hover table-row-dashed fs-6 gy-5 my-0 dataTable no-footer"
        >
          <tbody className="text-gray-600 fw-bold divide-y divide-gray-200 ">
            {emails.map((email) => (
              <tr
                key={email.id}
                className={
                  selectedItems.includes(email.id) ? "bg-light-success" : ""
                }
              >
                <td className="ps-9">
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={email.id}
                      data-kt-check="true"
                      checked={selectedItems.includes(email.id)}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setSelectedItems((selectedItems) => [
                            ...selectedItems,
                            email.id,
                          ]);
                        } else {
                          setSelectedItems(
                            selectedItems.filter((item) => item !== email.id)
                          );
                        }
                      }}
                    ></input>
                  </div>
                </td>
                <td>
                  <Link
                    to={"/emails/" + email.id}
                    className={
                      (email.is_read ? "fw-normal" : "fw-bold") +
                      " d-block text-reset"
                    }
                  >
                    {email.subject}{" "}
                  </Link>
                </td>
                <td>
                  <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6">
                    {email.from_name}
                  </span>
                  <span className="text-muted fw-bold d-block">
                    {email.from}
                  </span>
                </td>
                <td className="w-150px text-end fs-7 pe-9">
                  <span className="text-muted fw-bold d-block">
                    {email.created_at && email.created_at.slice(0, 10)
                      ? email.created_at.slice(0, 10)
                      : ""}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
