import { useIntl } from "react-intl";
import { EnableSidebar, PageTitle } from "../../../../_metronic/layout/core";
import { CustomersTable } from "../components/CustomersTable";

const CustomersPage = () => {
  const intl = useIntl();

  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "customers" })}
      </PageTitle>
      <CustomersTable />
    </EnableSidebar>
  );
};

export default CustomersPage;
