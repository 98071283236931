import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { DisableSidebar } from "../../_metronic/layout/core";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import UsersPage from "../modules/users/pages/UsersPage";
import ViewUser from "../modules/users/pages/ViewUser";
import AddUser from "../modules/users/pages/AddUser";
import CustomersPage from "../modules/customers/pages/CustomersPage";
import AddCustomer from "../modules/customers/pages/AddCustomer";
import ViewCustomer from "../modules/customers/pages/ViewCustomer";
import { ProfileDetails } from "../modules/accounts/components/settings/cards/ProfileDetails";
import EmailsPage from "../modules/email/pages/EmailsPage";

const PrivateRoutes = () => {
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="profile" element={<ProfileDetails />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        {/* Lazy Modules */}

        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        {/* Felhasználók */}
        <Route path="/users" element={<UsersPage />} />
        <Route path="/users/create" element={<AddUser />} />
        <Route path="/users/:id" element={<ViewUser />} />
        {/* Ügyfelek */}
        <Route path="/customers" element={<CustomersPage />} />
        <Route path="/customers/create" element={<AddCustomer />} />
        <Route path="/customers/:id" element={<ViewCustomer />} />
        {/* E-mailek */}
        <Route path="/emails" element={<EmailsPage />} />
        <Route path="/emails/:id" element={<EmailsPage action="view" />} />
        {/* Error Pages */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  );
};

export { PrivateRoutes };
