import { useEffect, useState } from "react";
import axios from "axios";
import { KTCardBody } from "../../../../_metronic/helpers";
import { useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { Error403 } from "../../errors/components/Error403";
import GenFontAwe from "../../../../components/GenFontAwe";
import { TableHeader } from "../../../../components/TableHeader";
import Lottie from "lottie-react";
import loadingAnimation from "../../../../lottie/loadingAnimation.json";

const UsersTable = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState<any[]>([]);
  const intl = useIntl();

  const [error, setError] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const colors = {
    superadmin: "danger",
    administrator: "primary",
    support: "success",
  };

  const [searchParams, setSearchParams] = useState({
    page: 1,
    query: {
      name: "",
      email: "",
      id: "",
    },
  });
  const [sort, setSort] = useState({
    name: "id",
    direction: "desc",
  });

  const [perPage, setPerPage] = useState(10);

  const [pages, setPages] = useState<any[]>([]);
  const [url, setUrl] = useState(process.env.REACT_APP_API_URL + "/user");
  const [total, setTotal] = useState(0);

  async function getUsers() {
    setLoading(true);
    return await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
        },
        params: {
          filter: searchParams,
          sort: sort,
          per_page: perPage,
        },
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      })
      .then((response) => {
        setPages(response?.data.meta.links);
        setUsers(response?.data.data);
        setTotal(response?.data.meta.total);
        setLoading(false);
      });
  }

  useEffect(() => {
    getUsers();
  }, [searchParams, sort, url, perPage]);

  if (error) {
    console.log(error);
    return <Error403 />;
  }
  if (!users) {
    return (
      <KTCardBody className="py-4">
        <Lottie animationData={loadingAnimation} loop={true} />
      </KTCardBody>
    );
  }

  return (
    <div className="card position-relative">
      <div className="card-body">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-sm mt-2 me-2 btn-outline btn-outline-primary"
              onClick={(e) => {
                e.preventDefault();
                getUsers();
              }}
            >
              <GenFontAwe nameIco="fa-refresh" />
            </button>
          </div>
          <div className="col-6 text-end">
            <button
              className="btn btn-sm mt-2 me-2 btn-outline btn-outline-success"
              onClick={(e) => {
                navigate("/users/create", { replace: true });
              }}
            >
              <GenFontAwe nameIco="fa-user-plus" customClass="me-1" />
              {intl.formatMessage({ id: "new_user" })}
            </button>
          </div>
        </div>
        {loading && (
          <div className="mt-25 position-absolute text-center top-25 w-100">
            <Lottie
              animationData={loadingAnimation}
              loop={true}
              className="w-100 h-100px mx-auto"
            />
          </div>
        )}
        <table className="table table-striped table-row-bordered gy-5 gs-7">
          <TableHeader
            activeSort={sort}
            onSortChange={(newSort: any) => setSort(newSort)}
            columns={[
              {
                name: intl.formatMessage({ id: "id" }),
                id: "id",
                type: "text",
                orderable: true,
                searchable: true,
                filter: "",

                onkeyup: (e: any) => {
                  if (e.key === "Enter" || e.currentTarget.value === "") {
                    setSearchParams({
                      ...searchParams,
                      query: {
                        ...searchParams.query,
                        id: e.currentTarget.value,
                      },
                    });
                  }
                },
              },
              {
                name: intl.formatMessage({ id: "name" }),
                id: "name",
                type: "text",
                orderable: true,
                searchable: true,
                filter: "",

                onkeyup: (e: any) => {
                  if (e.key === "Enter" || e.currentTarget.value === "") {
                    setSearchParams({
                      ...searchParams,
                      query: {
                        ...searchParams.query,
                        name: e.currentTarget.value,
                      },
                    });
                  }
                },
              },
              {
                name: intl.formatMessage({ id: "role" }),
                type: "text",
              },
              {
                name: "Email",
                id: "email",
                type: "search",
                orderable: true,
                searchable: true,
                filter: "",

                onkeyup: (e: any) => {
                  if (e.key === "Enter" || e.currentTarget.value === "") {
                    setSearchParams({
                      ...searchParams,
                      query: {
                        ...searchParams.query,
                        email: e.currentTarget.value,
                      },
                    });
                  }
                },
              },
              {
                name: intl.formatMessage({ id: "created_at" }),
                id: "created_at",
                type: "date",
                orderable: true,
              },
              {
                name: intl.formatMessage({ id: "updated_at" }),
                id: "updated_at",
                type: "date",
                orderable: true,
              },
            ]}
          />
          <tbody
            className={
              "text-gray-600 fw-bold divide-y divide-gray-200 " +
              (loading ? "opacity-50" : "")
            }
          >
            {users.map((user) => (
              <tr key={user.id}>
                <td className="fw-bold w-100px">{user?.id}</td>
                <td className="fw-bolder ">
                  <Link
                    className="text-reset badge-outline"
                    to={"/users/" + user.id}
                  >
                    <img
                      src={user?.avatar}
                      className="w-20px me-2 rounded-circle align-self-center"
                      alt="avatar"
                    />
                    {/* <img src={user?.avatar} className="w-30px me-2" alt="avatar" /> */}
                    <span className="align-self-center">{user?.name}</span>
                  </Link>
                </td>

                <td className="w-200px">
                  {user?.role_names?.map((role: any) => (
                    <span
                      key={role}
                      className={
                        "m-1 badge badge-outline badge-" +
                        colors[role] +
                        " me-2"
                      }
                    >
                      {intl.formatMessage({ id: role })}
                    </span>
                  ))}
                </td>
                <td>{user?.email}</td>
                <td>{user?.created_at}</td>
                <td>{user?.updated_at}</td>
              </tr>
            ))}
            {!users.length && (
              <tr>
                <td colSpan={5} className="text-center">
                  {intl.formatMessage({ id: "no_results" })}
                </td>
              </tr>
            )}
          </tbody>
          <tfoot
            className={
              "text-gray-600 bg-gray-200 fw-bold divide-y divide-gray-200 " +
              (loading ? "opacity-0" : "")
            }
          >
            <tr className="">
              <td colSpan={6} className="px-0">
                {users.length && (
                  <div className="row">
                    <div className="col-6 col-lg-3 align-self-center">
                      <select
                        className="form-select form-select-sm w-auto me-2"
                        defaultValue={perPage}
                        onChange={(e) => {
                          setPerPage(parseInt(e.currentTarget.value));
                        }}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                    <div
                      className="col-6 text-end col-lg align-self-center"
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage(
                          { id: "total_items" },
                          {
                            total:
                              "<strong class='text-dark'>" +
                              total +
                              "</strong>",
                          }
                        ),
                      }}
                    ></div>
                    <div className="col-12 col-lg pe-0">
                      <nav aria-label="">
                        <ul className="pagination justify-content-lg-end justify-content-center">
                          {pages.map((page) => (
                            <li
                              className={
                                "page-item " +
                                (page.active ? "active" : "") +
                                " me-2"
                              }
                              key={page.label}
                            >
                              <Link
                                className="page-link"
                                to="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (page.url) setUrl(page.url);
                                }}
                              >
                                {page.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export { UsersTable };
