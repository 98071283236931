import { ComponentProps } from "react";
import { useIntl } from "react-intl";

type ButtonProperties = {
  label: string;
  className?: string;
  type: "submit" | "button";
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  theme:
    | "btn-primary"
    | "btn-secondary"
    | "btn-success"
    | "btn-danger"
    | "btn-warning"
    | "btn-info";
};
const Button = ({
  label,
  className = "btn ",
  type = "submit",
  onClick,
  disabled = false,
  loading = false,
  theme = "btn-primary",
  ...props
}: ButtonProperties) => {
  const intl = useIntl();
  return (
    <div {...props}>
      <button
        onClick={onClick}
        type={type}
        className={className + theme}
        data-kt-indicator={loading ? "on" : "off"}
        disabled={loading || disabled}
      >
        <span className="indicator-label">{label}</span>
        <span className="indicator-progress">
          {intl.formatMessage({ id: "please_wait" })}{" "}
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </button>
    </div>
  );
};

export default Button;
