import { useEffect, useState } from "react";
import axios from "axios";
import { KTCardBody } from "../../../../_metronic/helpers";
import { useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { Error403 } from "../../errors/components/Error403";
import GenFontAwe from "../../../../components/GenFontAwe";
import { TableHeader } from "../../../../components/TableHeader";
import Lottie from "lottie-react";
import loadingAnimation from "../../../../lottie/loadingAnimation.json";

const CustomersTable = () => {
  const navigate = useNavigate();

  const [customers, setCustomers] = useState<any[]>([]);
  const intl = useIntl();

  const [error, setError] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const colors = {
    administrator: "primary",
    support: "success",
  };

  const [searchParams, setSearchParams] = useState({
    page: 1,
    query: {
      name: "",
      company_name: "",
      email: "",
      phone: "",
    },
  });
  const [sort, setSort] = useState({
    name: "name",
    direction: "asc",
  });

  const [pages, setPages] = useState<any[]>([]);
  const [url, setUrl] = useState(process.env.REACT_APP_API_URL + "/customer");
  const [total, setTotal] = useState(0);

  async function getCustomers() {
    setLoading(true);
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
        },
        params: {
          filter: searchParams,
          sort: sort,
        },
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      })
      .then((response) => {
        setPages(response?.data.meta.links);
        setCustomers(response?.data.data);
        setTotal(response?.data.meta.total);
        setLoading(false);
      });
  }

  // event on unmount
  useEffect(() => {
    return () => {
      console.log("unmounting...");
    };
  }, []);

  useEffect(() => {
    getCustomers();
  }, [searchParams, sort, url]);

  if (error) {
    console.log(error);
    return <Error403 />;
  }
  if (!customers) {
    return (
      <KTCardBody className="py-4">
        <Lottie animationData={loadingAnimation} loop={true} />
      </KTCardBody>
    );
  }

  return (
    <div className="card position-relative">
      <div className="card-body">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-sm btn-primary mt-2 btn-icon me-2"
              onClick={(e) => {
                e.preventDefault();
                getCustomers();
              }}
            >
              <GenFontAwe nameIco="fa-refresh" />
            </button>
            <button
              className="btn btn-sm btn-success mt-2 me-2"
              onClick={(e) => {
                navigate("/customers/create", { replace: true });
              }}
            >
              <GenFontAwe nameIco="fa-plus" customClass="me-1" />
              {intl.formatMessage({ id: "new_customer" })}
            </button>
          </div>
        </div>
        {loading && (
          <div className="mt-25 position-absolute text-center top-25 w-100">
            <Lottie
              animationData={loadingAnimation}
              loop={true}
              className="w-100 h-100px mx-auto"
            />
          </div>
        )}
        <table className="table gy-7">
          <TableHeader
            activeSort={sort}
            onSortChange={(newSort: any) => setSort(newSort)}
            columns={[
              {
                name: intl.formatMessage({ id: "name" }),
                id: "name",
                type: "text",
                orderable: true,
                searchable: true,
                filter: "",

                onkeyup: (e: any) => {
                  if (e.key === "Enter" || e.currentTarget.value === "") {
                    setSearchParams({
                      ...searchParams,
                      query: {
                        ...searchParams.query,
                        name: e.currentTarget.value,
                      },
                    });
                  }
                },
              },
              {
                name: intl.formatMessage({ id: "company_name" }),
                id: "company_name",
                type: "text",
                orderable: true,
                searchable: true,
                filter: "",

                onkeyup: (e: any) => {
                  if (e.key === "Enter" || e.currentTarget.value === "") {
                    setSearchParams({
                      ...searchParams,
                      query: {
                        ...searchParams.query,
                        company_name: e.currentTarget.value,
                      },
                    });
                  }
                },
              },

              {
                name: "Email",
                id: "email",
                type: "search",
                orderable: true,
                searchable: true,
                filter: "",

                onkeyup: (e: any) => {
                  if (e.key === "Enter" || e.currentTarget.value === "") {
                    setSearchParams({
                      ...searchParams,
                      query: {
                        ...searchParams.query,
                        email: e.currentTarget.value,
                      },
                    });
                  }
                },
              },
              {
                name: intl.formatMessage({ id: "phone" }),
                id: "phone",
                type: "text",
                orderable: true,
                searchable: true,
                filter: "",

                onkeyup: (e: any) => {
                  if (e.key === "Enter" || e.currentTarget.value === "") {
                    setSearchParams({
                      ...searchParams,
                      query: {
                        ...searchParams.query,
                        phone: e.currentTarget.value,
                      },
                    });
                  }
                },
              },
              {
                name: intl.formatMessage({ id: "created_at" }),
                id: "created_at",
                type: "date",
                orderable: true,
              },
              {
                name: intl.formatMessage({ id: "updated_at" }),
                id: "updated_at",
                type: "date",
                orderable: true,
              },
            ]}
          />
          <tbody
            className={
              "text-gray-600 fw-bold divide-y divide-gray-200 " +
              (loading ? "opacity-50" : "")
            }
          >
            {customers.map((customer) => (
              <tr key={customer.id}>
                <td className="fw-bolder">
                  <Link
                    className="text-resetbadge-outline"
                    to={"/customers/" + customer.id}
                  >
                    {customer?.name}
                  </Link>
                </td>

                <td>
                  {customer?.role_names?.map((role: any) => (
                    <span
                      key={role}
                      className={
                        "badge badge-outline badge-" + colors[role] + " me-2"
                      }
                    >
                      {intl.formatMessage({ id: role })}
                    </span>
                  ))}
                </td>
                <td>{customer?.email}</td>
                <td>{customer?.phone}</td>
                <td>{customer?.created_at}</td>
                <td>{customer?.updated_at}</td>
              </tr>
            ))}
            {!customers.length && (
              <tr>
                <td colSpan={5} className="text-center">
                  {intl.formatMessage({ id: "no_results" })}
                </td>
              </tr>
            )}
          </tbody>
          <tfoot
            className={
              "text-gray-600 fw-bold divide-y divide-gray-200 " +
              (loading ? "opacity-0" : "")
            }
          >
            <tr>
              <td colSpan={5}>
                {customers && (
                  <div className="row">
                    <div className="col-12 col-lg-6 align-self-center">
                      {intl.formatMessage(
                        { id: "total_items" },
                        { total: total }
                      )}
                    </div>
                    <div className="col-12 col-lg-6">
                      <nav aria-label="">
                        <ul className="pagination justify-content-lg-end justify-content-center">
                          {pages.map((page) => (
                            <li
                              className={
                                "page-item " +
                                (page.active ? "active" : "") +
                                " me-2"
                              }
                              key={page.label}
                            >
                              <Link
                                className="page-link"
                                to="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (page.url) setUrl(page.url);
                                }}
                              >
                                {page.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export { CustomersTable };
