import { useState } from "react";
import GenFontAwe from "./GenFontAwe";
import { useIntl } from "react-intl";

export function TableHeader({ columns, activeSort, onSortChange, ...props }) {
  const intl = useIntl();
  return (
    <thead>
      <tr className="fw-semibold fs-6 text-gray-800 justify-content">
        {columns.map((column) => (
          <th key={column.name} className="px-2 py-1">
            <div className="justify-content-between d-flex">
              <div className="h-40px align-items-center d-flex">
                {column.name}
              </div>
              {column.orderable && (
                <div className="h-40px">
                  <button
                    className={
                      "btn btn-sm btn-link cursor-pointer me-1 " +
                      (activeSort.name === column.id &&
                      activeSort.direction === "asc"
                        ? "active "
                        : "")
                    }
                    onClick={() => {
                      onSortChange({
                        name: column.id,
                        direction: "asc",
                      });
                    }}
                  >
                    <GenFontAwe nameIco="fa-square-chevron-up" isReg />
                  </button>
                  <button
                    className={
                      "btn btn-sm btn-link cursor-pointer me-1 " +
                      (activeSort.name === column.id &&
                      activeSort.direction === "desc"
                        ? "active "
                        : "")
                    }
                    onClick={() => {
                      onSortChange({
                        name: column.id,
                        direction: "desc",
                      });
                    }}
                  >
                    <GenFontAwe nameIco="fa-chevron-down" isReg />
                  </button>
                </div>
              )}
            </div>
          </th>
        ))}
      </tr>
      <tr className="filters fw-semibold fs-6 text-gray-800 p-1">
        {columns.map((column) => (
          <th key={column.name} className="p-0">
            {column.searchable && (
              <input
                type={column.type}
                className="form-control form-control-sm form-control-white bg-transparent border-0  "
                defaultValue={column.filter}
                onKeyUp={column.onkeyup}
                // placeholder={"🔎 " + intl.formatMessage({ id: "search" })}
                placeholder={"🔎 "}
              />
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
}
