import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAuth } from "../../auth";
import { Link } from "react-router-dom";
import GenFontAwe from "../../../../components/GenFontAwe";
import { SocketContext } from "../../auth/core/Chat";

export function EmailSidebar({ initMails = [], initLabels = [], unreadCount }) {
  const intl = useIntl();

  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [newLabel, setNewLabel] = useState<any>(null);
  const [newLabelColor, setNewLabelColor] = useState<any>(null);
  const [labels, setLabels] = useState<any>(initLabels);
  const [mails, setMails] = useState<any>(initMails);
  const [totalUnreadInboxCount, setTotalUnreadInboxCount] = useState(
    unreadCount
  );

  const [currentList, setCurrentList] = useState("inbox");

  const { socket } = useContext(SocketContext);
  const reloadCounts = useCallback(() => {
    async function getMailCounts() {
      console.log("mails checking");
      const url = process.env.REACT_APP_API_URL + "/mail/getMailCount";
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
            Accept: "application/json",
          },
        })
        .catch((error) => {
          setError(error);
          console.log(error);
        })
        .then((response) => {
          console.log(response?.data);
          setLabels(response?.data.labels);
          setTotalUnreadInboxCount(response?.data.totalUnreadInboxCount);
        });
    }
    // getMailCounts();
  }, []);

  useEffect(() => {
    socket?.on("new-message", (data: any) => {
      console.log(data);
      reloadCounts();
      setTotalUnreadInboxCount(data.unreadMessagesCount);
    });
  }, [socket, reloadCounts]);

  // useEffect(() => {
  //   return () => {
  //     window.Echo.channel("private-test").stopListening(
  //       ".NewMailReceived",
  //       reloadCounts
  //     );
  //   };
  // }, [reloadCounts]);

  const addLabelButtonHandler = () => {
    setAddingLabel(!addingLabel);
  };
  const { currentUser, logout } = useAuth();

  const addLabelFormHandler = () => {
    setAddingLabel(!addingLabel);

    const url = process.env.REACT_APP_API_URL + "/mail/label/add";

    const response = axios
      .post(
        url,
        {
          name: newLabel,
          color: newLabelColor,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setLabels([...labels, response.data]);
      });
  };

  const [addingLabel, setAddingLabel] = useState(false);

  if (!currentUser) {
    return null;
  }

  return (
    <div
      className="card card-flush mb-0"
      data-kt-sticky="true"
      data-kt-sticky-name="docs-sticky-summary"
      data-kt-sticky-offset="{default: false, xl: '200px'}"
      data-kt-sticky-width="{lg: '250px', xl: '300px'}"
      data-kt-sticky-left="auto"
      data-kt-sticky-top="100px"
      data-kt-sticky-animation="false"
      data-kt-sticky-zindex="95"
    >
      <div className="card-body">
        <Link to="#" className="btn btn-primary fw-bold w-100 mb-8">
          {intl.formatMessage({ id: "new_email" })}
        </Link>

        <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-5">
          <Link
            to={"/emails"}
            className="menu-item mb-3"
            onClick={() => {
              setCurrentList("inbox");
              reloadCounts();
            }}
          >
            <span
              className={
                "menu-link " + (currentList === "inbox" ? "active" : "")
              }
            >
              <span className="menu-icon">
                <i className="ki-duotone ki-sms fs-2 me-3">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </span>
              <span className="menu-title fw-bold">
                {intl.formatMessage({ id: "inbox" })}
              </span>
              {totalUnreadInboxCount > 0 && (
                <span className="badge badge-light-success">
                  {totalUnreadInboxCount}
                </span>
              )}
            </span>
          </Link>

          {/* <div className="menu-item mb-3">
            <span className="menu-link">
              <span className="menu-icon">
                <i className="ki-duotone ki-abstract-23 fs-2 me-3">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </span>
              <span className="menu-title fw-bold">
                {intl.formatMessage({ id: "marked" })}
              </span>
            </span>
          </div> */}

          {/* <div className="menu-item mb-3">
            <span className="menu-link">
              <span className="menu-icon">
                <i className="ki-duotone ki-file fs-2 me-3">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </span>
              <span className="menu-title fw-bold">
                {intl.formatMessage({ id: "draft" })}
              </span>
              <span className="badge badge-light-warning">5</span>
            </span>
          </div> */}

          <div className="menu-item mb-3">
            <span
              onClick={() => {
                setCurrentList("sent");
                reloadCounts();
              }}
              className={
                "menu-link " + (currentList === "sent" ? "active" : "")
              }
            >
              <span className="menu-icon">
                <i className="ki-duotone ki-send fs-2 me-3">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </span>
              <span className="menu-title fw-bold">
                {intl.formatMessage({ id: "sent" })}
              </span>
            </span>
          </div>

          <div className="menu-item">
            <span className="menu-link">
              <span className="menu-icon">
                <i className="ki-duotone ki-trash fs-2 me-3">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                </i>
              </span>
              <span className="menu-title fw-bold">
                {intl.formatMessage({ id: "trash" })}
              </span>
            </span>
          </div>
        </div>
        <div className="menu-item">
          <div className="menu-content pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              {intl.formatMessage({ id: "labels" })}
            </span>
          </div>
        </div>
        <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary">
          {labels.map((label: any) => (
            <div className="menu-item mb-3" key={label.id}>
              <span className="menu-link">
                <span className="menu-icon">
                  <GenFontAwe
                    nameIco="fa-tags"
                    isDuoTone={true}
                    customClass="fs-2 me-3"
                    color={label.color}
                  />{" "}
                </span>
                <span className="menu-title fw-semibold">{label.name}</span>
                {/* <span className="badge badge-light-danger">6</span> */}
              </span>
            </div>
          ))}

          {!addingLabel && (
            <div className="menu-item">
              <span className="menu-link" onClick={addLabelButtonHandler}>
                <span className="menu-icon">
                  <i className="ki-duotone ki-plus fs-2 me-3 lh-0"></i>{" "}
                </span>
                <span className="menu-title fw-semibold">
                  {intl.formatMessage({ id: "add_label" })}
                </span>
              </span>
            </div>
          )}

          {addingLabel && (
            <form
              className="d-flex flex-column mt-3"
              onSubmit={addLabelFormHandler}
            >
              <div className="d-flex justify-content-between">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  required={true}
                  onChange={(e) => {
                    setNewLabel(e.target.value);
                  }}
                  placeholder={intl.formatMessage({ id: "new_label_name" })}
                />
                <input
                  type="color"
                  className="form-control form-control-sm w-40px form-control-flush pe-0 rounded-2"
                  defaultValue={"#FFFFFF"}
                  onChange={(e) => {
                    setNewLabelColor(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="d-flex justify-content-between mt-3">
                <button
                  type="button"
                  className="btn btn-sm btn-light me-2"
                  onClick={addLabelButtonHandler}
                >
                  {intl.formatMessage({ id: "cancel" })}
                </button>
                <button type="submit" className="btn btn-sm btn-primary">
                  {intl.formatMessage({ id: "add" })}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
