import { useIntl } from "react-intl";
import { EnableSidebar, PageTitle } from "../../../../_metronic/layout/core";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import Lottie from "lottie-react";
import loadingAnimation from "../../../../lottie/loadingAnimation.json";

import Input from "../../../CoreComponents/Input";
import Button from "../../../CoreComponents/Button";

type invalidMessages = {
  name: string | undefined;
  email: string | undefined;
};

const ViewUser = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [user, setUser] = useState<any>();
  const [roles, setRoles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [invalidMessages, setInvalidMessages] = useState<invalidMessages>({
    name: undefined,
    email: undefined,
  });

  const confirm = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: intl.formatMessage({ id: "delete_confirm" }),
      icon: "fa-solid fa-exclamation-triangle text-danger",
      rejectLabel: intl.formatMessage({ id: "no" }),
      acceptIcon: "fa-solid fa-check",
      acceptLabel: intl.formatMessage({ id: "yes" }),
      accept: async () => await deleteUser(),
    });
  };

  async function deleteUser() {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/user/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
        },
      })
      .catch((error) => {
        console.warn(error);
      })
      .then((response) => {
        if (response?.status !== 204) {
          toast.error(intl.formatMessage({ id: "no_permission" }));
        } else {
          navigate("/users", { replace: true });
        }
      });
  }

  async function updateUser() {
    setIsLoading(true);
    setInvalidMessages({
      name: undefined,
      email: undefined,
    });
    await axios
      .put(process.env.REACT_APP_API_URL + "/user/" + id, user, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
        },
      })
      .catch(function (error) {
        setIsLoading(false);
        toast.clearWaitingQueue();
        toast.warn(intl.formatMessage({ id: "error_action" }));
        if (error?.response.status === 422) {
          // each validation messages by key
          let error_array = {
            name: undefined,
            email: undefined,
          };
          if (error?.response.data.errors) {
            Object.keys(error?.response.data.errors).forEach((key) => {
              console.log(error?.response.data.errors[key][0]);
              error_array[key] = error?.response.data.errors[key][0];
              // setInvalidMessages({
              //   ...invalidMessages,
              //   [key]: error?.response.data.errors[key][0],
              // });
            });
            setInvalidMessages(error_array);
          }
        }
      })
      .then((response) => {
        setIsLoading(false);
        if (response) {
          toast.success(intl.formatMessage({ id: "success_action" }));
          navigate("/users", { replace: true });
        }
      });
  }

  async function getRoles() {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/settings/roles",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
        },
      }
    );
    setRoles(response.data.data);
  }

  async function getUser() {
    const response = await axios
      .get(process.env.REACT_APP_API_URL + "/user/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("kt-auth-react-v")}`,
        },
      })
      .then((response) => {
        setUser(response.data.data);
      });
  }

  useEffect(() => {
    getUser();
    getRoles();
  }, []);

  if (!user) {
    return (
      <EnableSidebar>
        <div className="mt-25 position-absolute top-25 w-100">
          <Lottie
            animationData={loadingAnimation}
            loop={true}
            className="w-75 h-100px "
          />
        </div>
      </EnableSidebar>
    );
  }

  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>{user?.name}</PageTitle>
      <div className="card text-left">
        <div className="card-body">
          <div className="row mt-3">
            <div className="col-lg-6">
              {/* <label className="form-label">
                {intl.formatMessage({ id: "name" })}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={user.name}
                onChange={(e) => {
                  setUser({ ...user, name: e.currentTarget.value });
                }}
              /> */}
              <Input
                label={intl.formatMessage({ id: "name" })}
                defaultValue={user.name}
                invalidMessage={invalidMessages.name}
                onChange={(e) => {
                  setUser({ ...user, name: e.currentTarget.value });
                }}
              />
            </div>
            <div className="col-lg-6">
              {/* <label className="form-label">E-mail</label>
              <input
                type="text"
                className="form-control"
                defaultValue={user.email}
                onChange={(e) => {
                  setUser({ ...user, email: e.currentTarget.value });
                }}
              /> */}
              <Input
                label="E-mail"
                defaultValue={user.email}
                invalidMessage={invalidMessages.email}
                onChange={(e) => {
                  setUser({ ...user, email: e.currentTarget.value });
                }}
                required={true}
              />
            </div>
          </div>
          <div className="row mt-10">
            <div className="col-lg-12">
              <h3>{intl.formatMessage({ id: "role" })}</h3>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {roles.map((role) => (
                  <div className="mb-2 me-2" key={role.id}>
                    <div className="form-check form-switch form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={role.id}
                        id={"flexSwitchDefault" + role.id}
                        checked={user.roles.includes(role.id)}
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            setUser({
                              ...user,
                              roles: [...user.roles, role.id],
                            });
                          } else {
                            setUser({
                              ...user,
                              roles: user.roles.filter((r) => r !== role.id),
                            });
                          }
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"flexSwitchDefault" + role.id}
                      >
                        {intl.formatMessage({ id: role.name })}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col text-start">
              <button
                type="button"
                className="btn btn-danger"
                onClick={confirm}
              >
                {intl.formatMessage({ id: "delete" })}
              </button>
              <ConfirmPopup />
            </div>
            <div className="col text-end">
              <Button
                label={intl.formatMessage({ id: "save" })}
                type="button"
                onClick={updateUser}
                loading={isLoading}
                theme="btn-primary"
              />
            </div>
          </div>
        </div>
      </div>
    </EnableSidebar>
  );
};

export default ViewUser;
