import { ComponentProps, useState } from "react";

type InputProperties = ComponentProps<"input"> & {
  defaultValue?: string;
  label?: string;
  onChange?: (e: any) => void;
  className?: string;
  type?: string;
  required?: boolean;
  invalidMessage?: string | undefined;
  placeholder?: string;
};
const Input = ({
  defaultValue,
  label,
  onChange,
  className = "form-control",
  type = "text",
  required = false,
  invalidMessage = undefined,
  placeholder = "",
  ...props
}: InputProperties) => {
  return (
    <div {...props}>
      {label === undefined ? null : (
        <label className={"form-label " + (required ? "required" : "")}>
          {label}
        </label>
      )}
      <input
        type={type}
        className={className + (invalidMessage ? " is-invalid" : "")}
        defaultValue={defaultValue}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
      />
      <div className="invalid-feedback">{invalidMessage}</div>
    </div>
  );
};

export default Input;
