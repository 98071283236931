import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solid from '@fortawesome/pro-solid-svg-icons'
// import * as regular from '@fortawesome/pro-regular-svg-icons'
import * as duoTone from '@fortawesome/pro-duotone-svg-icons'

const UpperFirst = (sentence) => {
    if (!sentence || (typeof sentence !== 'string')) return null
    return `${sentence.charAt(0).toUpperCase()}${sentence.slice(1)}`
}

const GenFontAwe = ({ isReg = false, customClass = '', nameIco = "", color = false, isDuoTone = false }) => {
    if (!nameIco) return null
    const finalName = nameIco.split('-').map((cv, ind) => ind === 0 ? cv : UpperFirst(cv)).join('')
    const finalIcon = isReg ? duoTone[finalName] : solid[finalName]
    if (isDuoTone) {
        const finalIcon = duoTone[finalName]
        return <FontAwesomeIcon color={color ? color : null} icon={finalIcon} className={customClass || ''} />
    } else {
        const finalIcon = isReg ? duoTone[finalName] : solid[finalName]
        return <FontAwesomeIcon color={color ? color : null} icon={finalIcon} className={customClass || ''} />
    }

    if (!finalIcon) return null
}

export default GenFontAwe