import { useIntl } from "react-intl";
import { EnableSidebar, PageTitle } from "../../../../_metronic/layout/core";
import { useAuth } from "../../auth";
import { UsersTable } from "../components/UsersTable";

const UsersPage = () => {
  const intl = useIntl();

  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "users" })}
      </PageTitle>
      <UsersTable />
    </EnableSidebar>
  );
};

export default UsersPage;
