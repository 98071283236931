import { useIntl } from "react-intl";
import { EnableSidebar, PageTitle } from "../../../_metronic/layout/core";
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from "../../../_metronic/partials/widgets";
import { useAuth } from "../../modules/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const DashboardPage = () => (
  <>
    {/* <FontAwesomeIcon icon={icon({ name: "coffee", style: "regular" })} /> */}
  </>
);

const DashboardWrapper = () => {
  const { currentUser, logout } = useAuth();
  const intl = useIntl();

  return (
    <EnableSidebar>
      <PageTitle description="You’ve got 24 New Sales" breadcrumbs={[]}>
        {intl.formatMessage({ id: "welcomeUser" }, { name: currentUser?.name })}
      </PageTitle>
      <DashboardPage />
    </EnableSidebar>
  );
};

export { DashboardWrapper };
